<div class="modal-header px-5 pt-5">
    <div class="d-flex align-items-center justify-content-between mb-3">
      <h4 class="m-0">Ready to submit?</h4>
      <!-- <h6 class="modal-title">{{ title }}</h6> -->
      <button type="button" class="close" aria-label="Close" (click)="cancelModal()"[disabled]="!submitLoader ? null : 'disabled'"
      ><span aria-hidden="true">&times;</span></button>
    </div>
    <p class="m-0">The documents will be sent for review, and you will not be able to make any changes till the process is complete</p>
</div> 
<div class="modal-body px-5" >  
  <span *ngIf="pdfSrc">
    <object type="application/pdf" #pdfviewobj id="pdfviewobj" [data]="pdfSrc" width="100%" height="500"></object>
  </span>
  <span *ngIf="!pdfSrc" class="align-items-center">
    <i class="fa-solid fa-spinner fa-spin-pulse fa-2xl"></i>
  </span>
</div>
<div class="modal-footer justify-content-start px-5 pb-5" *ngIf="pdfSrc"> 
  <button type="submit" class="btn btn-danger" (click)="finalSubmit()"
  [disabled]="pdfSrc && !submitLoader ? null : 'disabled'"  [ngClass]="pdfSrc && !submitLoader? '' : 'disabled'" >
     Submit
    <i class="fa-solid fa-spinner fa-spin-pulse ml-2" *ngIf="submitLoader"></i>
  </button> 
  <button
    type="button"
    class="btn btn-link"
    (click)="cancelModal()"  [disabled]="!submitLoader ? null : 'disabled'" >
    Cancel
  </button>
</div>
