import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { AuthGuardGuard } from './auth/auth-guard.guard';
import { RequestDetailsListComponent } from './request-details-list/request-details-list.component';

const routes: Routes = [  
  {
	path: 'app/envelope',
	component: AppComponent,
	canActivate : [AuthGuardGuard],		
	children : [
		{ path: '', redirectTo: 'list', pathMatch: 'full' },
		{ path: 'list', component: RequestDetailsListComponent}	
	]
  },{ path: '', redirectTo: 'app/envelope', pathMatch: 'full' }	
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { 
  
}
