import { Component, OnInit ,Output,EventEmitter} from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AwsconnectService,ISubmitCustomerDocument } from '../../shared/awsconnect.service';
import { FileUploadService } from '../../shared/file-upload.service';
import { Router,ActivatedRoute } from '@angular/router';
import {  DatePipe } from '@angular/common';
//let swal = require('sweetalert2');
import swal from 'sweetalert2/dist/sweetalert2.js';
import { PDFDocument,degrees } from 'pdf-lib';
import { ViewPdfConfirmComponent } from './view-pdf-confirm/view-pdf-confirm.component';

@Component({
  selector: 'app-upload-document',
  templateUrl: './upload-document.component.html',
  styleUrls: ['./upload-document.component.scss']
})
export class UploadDocumentComponent implements OnInit {
  allowedExtensions = ["jpg","jpeg","png","pdf","txt","csv","xls","xlsx","doc","docx","msg"];
  files: any[] = [];
  progress = 0;
  requestDetails : any;
  documentDetails : any;
  documentStatus : string;
  uniqueRequestNumber :string;
  selectedDocumentName:string;
  selectedDocumentGroupName:string;
  selectedDocId:number;
  Unique_Envelope_Id:string;
  EnvelopeNumber:string;
  requestId : number;
  requestData : ISubmitCustomerDocument;
  stageDocument : ISubmitCustomerDocument;
  loader : boolean=false;  
  stageloader = false;
  @Output() modalEmiiter = new EventEmitter<any>();
  @Output() modalEmiiterCancel = new EventEmitter<any>();

  allowedFileTypes = "application/pdf,image/png,image/jpg,image/jpeg,.csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,"
                      + "text/plain,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-outlook,.msg";
  
  constructor(private _modalService: BsModalService,
    public bsModalRef: BsModalRef,private _awsconnectService: AwsconnectService,
    private _FileUploadService : FileUploadService) { }

  ngOnInit(): void {
    this.requestId = this.documentDetails.RequestId;
    this.documentStatus = this.documentDetails.DocumentStatus;
    this.uniqueRequestNumber = this.requestDetails.Unique_Request_Id; 
    this.selectedDocumentName = this.documentDetails.DocumentTypeName;
    this.selectedDocumentGroupName = this.documentDetails.DocumentGroupTypeName;
    this.selectedDocId = this.documentDetails.RequestedDocumentId;

    if(this.documentStatus && this.documentStatus.toLowerCase() == "in progress"){
      this.getStagedDocument();      
    }else if(this.documentStatus && (this.documentStatus.toLowerCase() != "in progress" && this.documentStatus.toLowerCase() != "new")){
      this.getSubmittedDocument();
    }
  }

  //**file drop functionality -start
  //when file is dropped
  onFileDropped(e){   
    this.prepareFilesList(e);
  }

  //when file is browsed
  fileBrowseHandler(e){    
    this.prepareFilesList(e);
  }

   // Convert Files list to normal array list
  async prepareFilesList(files: Array<any>) {   
    for (const item of files) { 
      //validate size
      if(item.size > (10*1000*1000)){
        swal.fire('Error',"File " + item.name + " size exceeds 10 MB",'error');
        break;          
      }

      let fileExtension = item.name.split('.').pop();
      if(!this.isInArray(this.allowedExtensions, fileExtension.toLowerCase())) {
        swal.fire('Error',"File " + item.name + " has invalid file type",'error');
        break;   
      }  
      
      //let fName = item.name.split('.');
      // if(fName.length > 2){
      //   swal.fire('Error',"File " + item.name + " has invalid name",'error');
      //   break;
      // }

      //this.scanFile(item).then(infected =>{
        item.progress = 0;        
        let value = this.getUnit(item.size);
        let i = this.files.length;
        if(this.files.length <= 0){
          i = 0;
        }   
        
        this.loader = true;
        this.files[i] = {
                  //lastModified: item.lastModified,
                  name:item.name,
                  progress: item.progress,
                  size: value[0],
                  type: item.type,
                  unit: value[1],
                  webkitRelativePath: "",
                  file: undefined,
                  fileKey : undefined,
                  url:undefined
                };        
        
        this.uploadFilesSimulator(i).then (() => {
          //const reader = new FileReader();
          
          //reader.readAsDataURL(item);
          //reader.onload = () => {
            //console.log(reader.result);
           // this.files[i].file = reader.result;
            
            this.stageFilesonUpload(item,i);
          //};
        });      
    }   
  } 
  
  isInArray(array, word) {
    return array.indexOf(word.toLowerCase()) > -1;
  }

  getUnit(file){    
    let size;
    let unit;
    if (file < 1000) {
      size = file;
      unit = "bytes";
    } else if (file < 1000*1000) {
      size =  file / 1000;
      unit = "KB";
    } else if (file < 1000*1000*1000) {
      size = file / 1000 / 1000;
      unit = "MB";
    } else {
      size = (file / 1000 /1000 /1000);
      unit = "GB";
    }
    return [size,unit];
  }

  getFileName(str) {
    if (str.length > 22) {
      return str.substr(0, 11) + '...' + str.substr(-11)
    }
    return str
  }

  getFileFormat(fileName) {
    let fileFormat;
    fileFormat = fileName.split('.').pop();
    if (fileFormat.toLowerCase() == 'jpg') {
      fileFormat = "/assets/images/JPG.svg";
    } else if (fileFormat.toLowerCase() == 'jpeg') {
      fileFormat = "/assets/images/JPG.svg";
    } else if (fileFormat.toLowerCase() == 'png') {
      fileFormat = "/assets/images/PNG.svg";
    } else if (fileFormat.toLowerCase() == 'pdf') {
      fileFormat = "/assets/images/PDF.svg";
    } else if (fileFormat.toLowerCase() == 'txt') {
      fileFormat = "/assets/images/txt.svg";
    }else if (fileFormat.toLowerCase() == 'csv') {
      fileFormat = "/assets/images/csv.svg";
    }else if (fileFormat.toLowerCase() == 'xls') {
      fileFormat = "/assets/images/xls.svg";
    }else if (fileFormat.toLowerCase() == 'xlsx') {
      fileFormat = "/assets/images/xlsx.svg";
    }else if (fileFormat.toLowerCase() == 'doc') {
      fileFormat = "/assets/images/doc.svg";
    }else if (fileFormat.toLowerCase() == 'docx') {
      fileFormat = "/assets/images/docx.svg";
    }else if (fileFormat.toLowerCase() == 'msg') {
      fileFormat = "/assets/images/msg.svg";
    }
    return fileFormat
  }

  deleteFile(index: number,deleteStaging=false) {    
    if(deleteStaging){
      let docKeys=[];
      docKeys.push({"key": this.files[index].fileKey});     
    //"docKey=" + this.files[index].fileKey;
      this._awsconnectService.deleteStagedDocuments(docKeys).subscribe(() =>{
        this.files.splice(index, 1);
      });
    }else{
      this.files.splice(index, 1);
    }
  }

  confirmDelete(index){
    swal.fire({
      title: "Are you sure?",
      text: "The documents once deleted cannot be retrieved",
      icon: false,
      showCancelButton: true,
      confirmButtonText: 'Yes, delete the file!',
      confirmButton: 'btn btn-success',
      cancelButton: 'btn btn-danger',
      cancelButtonText: 'Cancel',
      showCloseButton: true
     // showDenyButton: true,
     // denyButtonText: "Don't Submit,just save",
    }).then((confirm) =>{
        if(confirm.isConfirmed){
          this.deleteFile(index,true);
        }
    });
  }

  //simulate upload progress
  uploadFilesSimulator(index: number) {
    return new Promise<void>((resolve, reject) => {
      setTimeout(() => {
        if(this.files){
          if (index === this.files.length) {  
            resolve();       
            return;
          } else {
            const progressInterval = setInterval(() => {
                    if (this.files[index].progress === 100) {
                      clearInterval(progressInterval);
                      // this.uploadFilesSimulator(index + 1);
                      resolve();
                    } else {
                      this.files[index].progress += 5;
                    }
                    //console.log(this.files[index].progress);
                  }, 200);
            }       
          } 
        }, 1000);     
    });
   
  }

  async stageFilesonUpload(files,index){
    this.loader = true;
    /*let uploadedDoc = [];
    uploadedDoc.push({
      "fileName" : files.name,
      "fileContent" :files.file
    });   
 
    this.stageDocument =  {} as ISubmitCustomerDocument;
    this.stageDocument.requestId =  this.requestId ;
    this.stageDocument.customerIdentifier = localStorage.getItem('alerusId');//this.requestDetails.CustomerIdentifier;
    this.stageDocument.unique_env_id = this.Unique_Envelope_Id;
    this.stageDocument.unique_req_id = this.requestDetails.Unique_Request_Id;
    this.stageDocument.requested_document_details_Id = this.selectedDocId;
    this.stageDocument.documentName = this.selectedDocumentName;
    this.stageDocument.filedetails = uploadedDoc;
    //await this._awsconnectService.stageFilesonUpload(this.stageDocument).subscribe(*/

    let docName =  this.selectedDocId; //this.selectedDocumentName +'_'+ commneted documentname for #5838
    let filename = localStorage.getItem('alerusId') +'/'+  this.Unique_Envelope_Id +'/'+this.requestDetails.Unique_Request_Id +
                      '/' + docName + '/' + this.requestDetails.Unique_Request_Id +'_'+this.selectedDocId + '_' + files.name
    
    //this.files[index].name = filename;  

    await this._FileUploadService.getSignedUrl(files,filename).subscribe( url =>{      
      this._FileUploadService.uploadfileAWSS3(url,files.type,files).subscribe(res =>{ 
        let stageDoc = {} as any;
        stageDoc.requestId = this.requestId;
        stageDoc.requested_document_details_Id =  this.selectedDocId;
        stageDoc.fileName = filename;
        if(res && res['type']==4){
          this._awsconnectService.stageDocuments(stageDoc).subscribe(
            res =>{
              this.files[index].fileKey =  res[0];
              this.files[index].url = res[1];
              this.files[index].stageprogress = 100
              this.loader = false;
              //this.documentStatus ="In progress";
            },error=>{
              swal.fire('Error',"failed while uploaded file " + error,'error');
              this.files.splice(index, 1);
            });
          }
      },error=>{
        swal.fire('Error',"Error uploading the document "+ error,'error');
      });
    },error=>{
      swal.fire('Error',"Error getting the signed url "+ error,'error');
    });    
    //return files;
  }

  async getStagedDocument(file?,index?){
    let params = "s3Prefix=" + localStorage.getItem('alerusId')+'/' + this.Unique_Envelope_Id +'/'+ this.uniqueRequestNumber+'/'+ this.selectedDocId;
    //this.selectedDocumentName+'_'+ commnted for #5838
    this.stageloader = true;
    await this._awsconnectService.getStagedDocument(params).subscribe(
      res =>{
        this.stageloader = false;
        if(res && res.length>0){
          res.forEach(item => {
              //split file name 
              let custIdentifierLen = localStorage.getItem("alerusId").length;
              //var filenameSp = item[0][2].split('_');   
              var totalLenghtToremove= custIdentifierLen + (''+this.selectedDocId).length + 2;          
              let fileName = item[0][2].replace(item[0][2].substring(0,totalLenghtToremove), "");
              console.log(totalLenghtToremove) 
             //file size &unit            
             let fileSize = this.getUnit(item[0][3]); 
             let size = fileSize[0];
             let unit = fileSize[1]; 

              let sepFileName = item[0][2].split('.');
              let fileType = sepFileName[sepFileName.length-1];//(item[0][2].split('.'))[1];
              if(this.isInArray(this.allowedExtensions, fileType.toLowerCase())) {
                this.files.push({
                  name:fileName,
                  progress: 100,
                  size: size,
                  type: fileType,
                  unit: unit,
                  webkitRelativePath: "",
                  file: '',
                  fileKey : item[0][0],
                  url: item[0][1]              
                }); 
              }                
          });
        }
      },error=>{
        this.stageloader = false;
        swal.fire('Error',"failed while fetching staged documents " + error,'error');        
      });
  }  

  getSubmittedDocument(file?,index?){
    let params = "s3Prefix=" + localStorage.getItem('alerusId')+'/' + this.Unique_Envelope_Id +'/'+ this.uniqueRequestNumber+'/'+this.selectedDocId; //+this.selectedDocumentName+'_' ommnted for #5838
    this.stageloader = true;
    this._awsconnectService.getSubmittedDocument(params).subscribe(res =>{
       this.stageloader = false;
        if(res && res.length>0){
          res.forEach(item => {
            //split file name 
            let custIdentifierLen = localStorage.getItem("alerusId").length;
            let fileName;
            if(item[0][2].includes(custIdentifierLen) || fileName == ''){
              fileName =  item[0][2].replace(item[0][2].substring(0, custIdentifierLen+4), "");
            }else{
              fileName = item[0][2];
            }
            
            // if(fileName == '')
            //   fileName = item[0][2];
            //file size &unit            
            let fileSize = this.getUnit(item[0][3]); 
            let size = fileSize[0];
            let unit = fileSize[1];

            let fileType = (item[0][2].split('.'))[1];

              this.files.push({
                name:fileName,
                progress: 100,
                size: size,
                type: fileType,
                unit: unit,
                webkitRelativePath: "",
                file: '',
                fileKey : item[0][0],
                url: item[0][1]              
              });

          });
        }
    },error=>{
      this.stageloader = false;
    });
  }

  showPdfViewerConfirmDialog(): void {    
    if(!this.files || this.files.length == 0){
      swal.fire("Warning","Please attach a file to submit");
      return;
    }
    let pdfViewerConfirmDialog: BsModalRef;       
    
    setTimeout(() => {
      this.requestData =  {} as ISubmitCustomerDocument;
      this.requestData.requestId =  this.requestId ;
      this.requestData.customerIdentifier = localStorage.getItem('alerusId');
      this.requestData.unique_env_id = this.Unique_Envelope_Id;
      this.requestData.unique_req_id = this.requestDetails.Unique_Request_Id;
      this.requestData.requested_document_details_Id = this.selectedDocId;
      this.requestData.documentName = this.selectedDocumentName;
    //this.requestData.filedetails = uploadedDoc;

      this.loader = false;
      pdfViewerConfirmDialog = this._modalService.show(
        ViewPdfConfirmComponent,
        {
          class: 'modal-lg', 
          ignoreBackdropClick: true,
          initialState: {
            requestData: this.requestData,
            files:this.files,
            requestNumber :this.requestDetails.RequestNumber,
            documentName:this.selectedDocumentName,
            envelopeNumber : this.EnvelopeNumber,
            requestor: this.requestDetails.RequestedBy,
            requestor_email: this.requestDetails.Requestor_Email
          },
        }
      );
      
      pdfViewerConfirmDialog.content.onCancel.subscribe(() => {
        this.loader = false;        
       this.bsModalRef.hide();
        this.modalEmiiterCancel.emit();
      });
      pdfViewerConfirmDialog.content.onSave.subscribe(() =>{  
       this.loader = false;
       this.bsModalRef.hide();
       this.modalEmiiter.emit();
       let docKeys=[];
       for(let index=0;index<this.files.length;index++){
         docKeys.push({"key": this.files[index].fileKey}); 
        }     
       //"docKey=" + this.files[index].fileKey;
         this._awsconnectService.deleteStagedDocuments(docKeys).subscribe(() =>{
           //this.files.splice(index, 1);
         });         
         
      });
      
    },4000);
    this.loader = true;

     //pdfViewerConfirmDialog = this._modalService.show(ViewPdfConfirmComponent);
    //if(pdfViewerConfirmDialog){
     
   // }
  }

  close(){
    this.bsModalRef.hide();
    this.modalEmiiterCancel.emit();
  }

  //CR -2023
  submitFiles(){
    if(!this.files || this.files.length == 0){
      swal.fire("Warning","Please attach a file to submit");
      return;
    }else{      
      let filetoUpload = [];
      this.loader = true;
      this.requestData =  {} as ISubmitCustomerDocument;
      this.requestData.requestId =  this.requestId ;
      this.requestData.customerIdentifier = localStorage.getItem('alerusId');
      this.requestData.unique_env_id = this.Unique_Envelope_Id;
      this.requestData.unique_req_id = this.requestDetails.Unique_Request_Id;
      this.requestData.requested_document_details_Id = this.selectedDocId;
      this.requestData.documentName = this.selectedDocumentName; 
      this.requestData.filedetails = [];
      for(let i = 0;i< this.files.length ;i++){  

        if(this.files[i].size > (25*1000*1000)){
          swal.fire('Error',"File size exceeds 25 MB ,cannot be submitted" + this.files[i].name,'error');
          this.loader = false;
          return;          
        }             

        let docName = this.selectedDocId; // this.selectedDocumentName +'_'+ commnted for #5838
        let stageFileName = this.requestData.customerIdentifier +'/'+ this.requestData.unique_env_id +'/'+this.requestData.unique_req_id +
        '/' + docName + '/final/' + this.requestData.unique_req_id +'_'+ this.requestData.requested_document_details_Id + '_' + this.files[i].name;   
        let submitFileName = this.requestData.unique_req_id +'_'+ this.requestData.requested_document_details_Id + '_' + this.files[i].name;  
        //  this.getUrl(this.files[i],stageFileName).then(url =>{  
        //     this.uploadFiletoCrossAccount(url,this.files[i]).then(upl =>{              
                this.requestData.filedetails.push({"fileName" : this.files[i].name });                 
                  if( i == (this.files.length-1)){  
                    //console.log(this.requestData) 
                    this.submitandMoveFiles(this.requestData);
                  }               
        //     });
        //  });
      }  
    }   
  }

  getUrl(files,stageFileName){
    return new Promise<any>((resolve, reject) => {
      this._FileUploadService.getSignedUrl(files,stageFileName).subscribe( url =>{         
        resolve(url);
      },error=>{
          swal.fire('Error',"Error getting the signed url "+ files.name + " " + error,'error');
          reject();
        });
    });
  }

  uploadFiletoCrossAccount(url,files){
    return new Promise<any>((resolve, reject) => {
      this._FileUploadService.uploadfileAWSS3(url,files.type,files).subscribe(upl =>{     
        if(upl && upl['type']==4){ 
          resolve(upl);
        }
      },error=>{
        swal.fire('Error',"Error uploading the document "+ files.name + " " + error,'error');
        reject();
      });
    });
  }

  submitandMoveFiles(requestData){
    this._awsconnectService.submitDocument(this.requestData).subscribe(res=>{
      swal.fire("Success",'Documents Submitted Successfully');
      this.loader = false;
      this.bsModalRef.hide();                      
      this.sendEmail();
      this.modalEmiiter.emit();
      //this.onSave.emit();
    },error=>{
      this.loader = false;
      swal.fire('Error',"Error submitting the document " + error,'error');
    });    
  }

  sendEmail(){
    let params = "requestNumber=" + this.requestDetails.RequestNumber + "&documentName=" + this.selectedDocumentName + "&customerName=" +  localStorage.getItem('userName') + "&envelopeNumber=" + this.EnvelopeNumber + "&requestorEmail=" + this.requestDetails.Requestor_Email + "&requestor=" + this.requestDetails.RequestedBy;
    this._awsconnectService.sendEmail(params).subscribe(()=>{});
  }

}
