import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {  DatePipe } from '@angular/common';
import { HeaderComponent } from './shared/header/header.component';
import { FooterComponent } from './shared/footer/footer.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from './auth/auth.service';
import { HttpClientModule } from '@angular/common/http';
import { ModalModule } from 'ngx-bootstrap/modal';
//import { PdfViewerModule } from 'ng2-pdf-viewer';
import {AccordionModule} from 'primeng/accordion';

import { DragdropuploadDirective } from './shared/dragdropupload.directive';

import { ConfigServiceService } from './shared/config-service.service';
import { ViewPdfConfirmComponent } from './request-details-list/upload-document/view-pdf-confirm/view-pdf-confirm.component';
import { RequestDetailsListComponent } from './request-details-list/request-details-list.component';
import { UploadDocumentComponent } from './request-details-list/upload-document/upload-document.component';

export const configFactory = (configService: ConfigServiceService) => {
  return () => configService.loadConfig();
};

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        FooterComponent,
        DragdropuploadDirective,
        ViewPdfConfirmComponent,
        RequestDetailsListComponent,
        UploadDocumentComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        NgbModule,
        HttpClientModule,
        ModalModule.forChild(),
        AccordionModule
        //PdfViewerModule
    ],
    providers: [
        HttpClientModule,
        AuthService,
        {
            provide: APP_INITIALIZER,
            useFactory: configFactory,
            deps: [ConfigServiceService],
            multi: true
        },
        DatePipe
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
