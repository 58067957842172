import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ConfigServiceService {
  config: any;
  constructor(private http: HttpClient) { }

  loadConfig() {
    return this.http
      .get('./assets/appconfig.json')
      .toPromise()
      .then(config => {
        this.config = config;
      });
  }
}
