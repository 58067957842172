<div class="modal-header mb-0 px-5 pt-5 pb-0">
  <button type="button" class="close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
  <h5 class="modal-title font-weight-medium font-size-xl">{{selectedDocumentName}}</h5>
</div>
<div class="modal-body px-5 pb-0">
  <div *ngIf="documentStatus=='New' || documentStatus=='In Progress'">
    <div class="upload text-center px-4 py-5 mb-4" appDragdropupload (fileDropped)="onFileDropped($event)">
      <input type="file" #fileDropRef id="fileDropRef" (change)="fileBrowseHandler($event.target.files)" multiple accept="{{allowedFileTypes}}" capture="environment"/>
      <img class="mb-3" src="/assets/images/icon-material-cloud-upload.svg" alt="cloud icon">
      <h5 class="mb-2 font-size-md">Click here or drag and drop</h5>
      <p class="font-size-sm text-light-color m-0">Maximum upload size 10 MB</p>
      <p class="font-size-sm text-light-color m-0">Allowed extensions .png,.jpg,.jpeg,.pdf,.doc,.docx,.csv,.txt,.xls,.xlsx,.msg</p>
    </div>
  </div>
  <div class="max-h-220 scrollbar mb-4" [hidden]="files.length == 0">
    <div class="uploaded-list" #ref>
      <div class="uploaded-list-item pb-3 mb-3" [ngClass]="{'success' : file.progress == 100}" *ngFor="let file of files; let i = index">
        <div class="mr-3"><img class="doc-thumbnail" [src]="getFileFormat(file.name)" alt="document thumbnail"></div>
        <div class="mr-2 w-100">
          <a [href]="file.url" target="_blank" style="cursor: pointer;">                      
            <p class="m-0" [innerHTML]="getFileName(file.name)" title="{{file.name}}"></p>
          </a> 
          <span class="font-size-xxs text-light-color">{{file.size}} {{file.unit}}</span> 
          <div class="progress-cont" [hidden]="file.progress == 100">
            <div class="progress" [style.width]="file.progress + '%'">                            
            </div>
          </div>
        </div>
        <div class="ml-auto" *ngIf="(documentStatus=='New') || (documentStatus=='In Progress')"><!--documentStatus=='Submitted'-->
          <span class="action-btn d-none" [ngClass]="{'d-flex' : file.progress !== 100}" (click)="deleteFile(i)"><img src="/assets/images/icon-material-close.svg" alt=""></span> 
          <span class="action-btn d-none" [ngClass]="{'d-flex' : file.progress == 100}" (click)="confirmDelete(i)" *ngIf="file.fileKey"><img src="/assets/images/icon-open-trash.svg" alt="delete document"></span>
          <!--<button class="action-btn d-none" [ngClass]="{'d-flex' : file.progress == 100}" (click)="confirmDelete(i)" [disabled]="!file.fileKey"><img src="/assets/images/icon-open-trash.svg" alt="delete document"></button>-->
        </div>
      </div>
    </div>             
  </div>
  <div class="pt-5" *ngIf="files.length==0 && stageloader">
    <div class="d-flex flex-column align-items-center justify-content-center py-5">
      <i class="fa-solid fa-spinner fa-spin-pulse fa-2xl"></i>
    </div>
  </div>
</div>
<div class="modal-footer m-0 px-5 pb-5 pt-0">
  <div class="d-flex align-items-center justify-content-center w-100" *ngIf="(documentStatus=='New' || documentStatus=='In Progress')">
    <button class="btn btn-danger"  [ngClass]="(files.length>0 && (documentStatus=='New' || documentStatus=='In Progress'))&& !loader? '' : 'disabled'" 
      [disabled]="(files.length>0 && (documentStatus=='New' || documentStatus=='In Progress'))&& !loader? null : 'disabled'" (click)="submitFiles()">
      Submit
      <i class="fa-solid fa-spinner fa-spin-pulse ml-2" *ngIf="loader"></i>
    </button> <!--(click)="showPdfViewerConfirmDialog()"-->
    <button class="btn btn-link" (click)="close()">Cancel</button>
  </div>
</div>