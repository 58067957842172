import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from '../../auth/auth.service';
import { ConfigServiceService } from '../../shared/config-service.service';
let swal = require('sweetalert2');

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  mobMenu: boolean = false;
  alerusId:string="";
  userName :string;

  clickEvent(){
    this.mobMenu = !this.mobMenu;       
  }
  constructor(private _authService : AuthService,private configService: ConfigServiceService) { }

    ngOnInit(): void {
        this.getUserInfo();
    }

    // ngAfterContentChecked(){     
     
    // }

    async getUserInfo(){
      await this._authService.getUserAttributes().then(res=>{
        if(res)
          this.alerusId = res.attributes["custom:alerus360id"];
          this.userName = res.username;
          localStorage.setItem('alerusId',res.attributes["custom:alerus360id"]);
          localStorage.setItem('userName',res.username);
      },error=>{
        swal.fire('Error','For our customers security you have been logged out, please click the OK button to log back in.','error');        
      });
    }

    signOut(){
      this._authService.signOut();
    }
  }
