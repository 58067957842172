import { Injectable } from '@angular/core';
import { Inject,VERSION } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { environment } from '../../environments/environment';
import { AuthenticationDetails, CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js';
import { CognitoIdentityServiceProvider, ConfigService } from 'aws-sdk';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Auth } from '@aws-amplify/auth';
import { Observable, BehaviorSubject } from 'rxjs';
import { Subscription } from 'rxjs';
import { userInfo } from 'os';
import { ConfigServiceService } from '../shared/config-service.service';
import { resolve } from 'dns';


// var authPoolData = {
//   userPoolId: environment.cognito.userPoolId, // user pool id 
//   userPoolWebClientId: environment.cognito.userPoolWebClientId, // client id 
//   oauth : environment.oauth
// };

//var userPool = new CognitoUserPool(poolData);

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(private configService: ConfigServiceService) {
   
    var authPoolData = {
      userPoolId: configService.config.cognito.userPoolId, // user pool id 
      userPoolWebClientId: configService.config.cognito.userPoolWebClientId,//environment.cognito.userPoolWebClientId, // client id 
      oauth : configService.config.oauth,//environment.oauth
      cookieStorage : this.configService.config.redirectUrl.includes('localhost:8080') ? '' :configService.config.cookieStorage,
      redirect_url :this.configService.config.REDIRECT_URL
    };    
    //console.log(authPoolData.cookieStorage)
    Auth.configure(authPoolData);
  }

  // public async authenticate() : Promise<any>{
  //   let isAuth;
  //   await Auth.currentSession().then(sess =>{
  //     isAuth  = true;
  //     localStorage.setItem('token', sess.getIdToken().getJwtToken());
  //     localStorage.setItem('accessToken', sess.getAccessToken().getJwtToken());
  //     //console.log(sess.getIdToken().getJwtToken());  
  //      this.getUserAttributes();
  //   },error=>{
  //     isAuth=false;
  //     console.log(error); 
  //   });
  //   return isAuth;
  // }

  public async authenticate():Promise<any>{
    let isAuth;
    await new Promise<boolean>((resolve, reject) => { 
        Auth.currentAuthenticatedUser().then(curUser =>{
        isAuth  = true;
        this.getCurrentUserSession();
        this.SetUserAttributes(curUser);
        resolve(true);
      },error=>{
        isAuth=false;
        reject(false);
        console.log(error);
      })//.catch(()=>{
        //isAuth=false;
        //console.log("No User logged in");
       // reject("No User logged in");
      });      
    //})
    ;
    return isAuth;
  }

  public async getCurrentUserSession(): Promise<any>{
    await Auth.currentSession().then(sess =>{
      localStorage.setItem('token', sess.getIdToken().getJwtToken());
      localStorage.setItem('accessToken', sess.getAccessToken().getJwtToken());
    }).catch(()=>{     
      console.log("No User session found");
    });
  }

 /* Auth.currentAuthenticatedUser()
      .then(currentUser => setUser(currentUser))
      .catch(() => console.log("Not signed in"));*/
 
  public federatedSign():Observable<any>{  
    return new Observable(observer => {   
      Auth.federatedSignIn().then(res =>{     
        observer.next(true);
      },error=>{
        observer.next(false);
        console.log(error);  
      });
  });
  
  }
     
  public signOut(){
    //this.deleteAllCookies();
    localStorage.removeItem('token');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('alerusId');
    localStorage.removeItem('userName');
    Auth.signOut();    
    window.location.href = this.configService.config.redirectUrl;
  }  

  public async SetUserAttributes(userInfo){   
    //const userInfo = await Auth.currentAuthenticatedUser();
    localStorage.setItem('alerusId',userInfo.attributes["custom:alerus360id"]);
    localStorage.setItem('userName',userInfo.username);
    //return userInfo;
  }

  public async getUserAttributes():Promise<any>{   
    const userInfo = await Auth.currentAuthenticatedUser();
    localStorage.setItem('alerusId',userInfo.attributes["custom:alerus360id"]);
    localStorage.setItem('userName',userInfo.username);
    return userInfo;
  }
 

  deleteAllCookies() {
    var cookies = document.cookie.split(";");
    for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        var eqPos = cookie.indexOf("=");
        var name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
}

  //without amplify

  // public isLoggedIn(){
  //   var isAuth = false;
  //   var cognitoUser = userPool.getCurrentUser();
  //   if (cognitoUser != null) {
  //       cognitoUser.getSession((err: any, session: any) => {
  //         if (err) {            
  //           console.log(err.message || JSON.stringify(err));
  //         }
  //         isAuth = session.isValid();
  //       })
  //     }
  //     return isAuth;
  //   }   
  // public signIn() {
  //     let authenticationDetails = new AuthenticationDetails({
  //       Username: 'demo',
  //       Password: 'Alerus01',
  //     });
      
  //     let userData = { Username: 'demo', Pool: userPool };
  //     var cognitoUser = new CognitoUser(userData);   
  
  //      cognitoUser.authenticateUser(authenticationDetails,{
  //        onSuccess: (result) => {
  //         localStorage.setItem('token', result.getAccessToken().getJwtToken());      
  //          this.getCurrentUserAttr(result.getAccessToken().getJwtToken());
  //     //     //this.router.navigate(["dashboard"])
  //        },
  //       onFailure: (err) => {
  //         alert(err.message || JSON.stringify(err));
  //         //this.isLoading = false;
  //       },
  //      });
  //   }
  
  //   public getCurrentUserAttr(res?) : any{
  //     if(!res){
  //      res =  localStorage.getItem('token');
  //     }
  //     var params = {
  //       AccessToken:  res
  //   };
  //     var cognitoidentityserviceprovider = new CognitoIdentityServiceProvider({region : environment.cognito.region});
  //     cognitoidentityserviceprovider.getUser(params, function(err, data) {
  //       if (err) {
  //          console.log(err, err.stack);
  //       } // an error occurred
  //       else{
  //          console.log(data);
  //       } // successful response
  //       })
  //   }
}
export class User {
  username: string;
  // alerusId: string;
  // token: string;
}
