import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { UploadDocumentComponent } from './upload-document/upload-document.component';
import { AwsconnectService } from '../shared/awsconnect.service';
import { request } from 'http';
//let swal = require('sweetalert2');
import swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-request-details-list',
  templateUrl: './request-details-list.component.html',
  styleUrls: ['./request-details-list.component.scss']
})
export class RequestDetailsListComponent implements OnInit {
  
  fullRequestDetails : any=[];
  totalCount = 0;
  loader = false;
  activeIndexes : any =[];
  currentSelected : number = undefined;

  constructor(private _modalService: BsModalService,private _awsconnectService : AwsconnectService) { }

  ngOnInit(): void {
    this.requestDetails();
  }

  requestDetails(isSubmitted= false){
    this.getCustomerFullRequestDetails().then(res=>{      
      this.loader = false;
      if(res){
        //this.fullRequestDetails = res[0]["response"];
        this.totalCount = res[0]["totalCount"];    
        this.displayRecords(res[0]["response"],isSubmitted);  
       }
     },error =>{
      this.loader = false;
     });
  }

  async getCustomerFullRequestDetails(){
    let params="customerId=" + localStorage.getItem('alerusId');
    this.loader = true;
    return await new Promise((resolve, reject) => { 
      //this.fullRequestDetails =[];
      this._awsconnectService.getCustomerFullRequestDetails(params).subscribe({
        next: (data) => resolve(data),
        error: (err) => reject(err)
        }
      );
   });
  }

  displayRecords(requestDetails,isSubmitted?){
    this.activeIndexes = [];
    this.fullRequestDetails = [];
    let openEnvelopeList = [];
    //filter open envelopes
    openEnvelopeList = requestDetails.filter(res => res.EnvelopeStatus == "Open");
    //get distinct  envelope 
    let distinctEnvelopes = [];
    if(openEnvelopeList && openEnvelopeList.length >0){
      //distinctEnvelopes = openEnvelopeList.map(r => r.Unique_Envelope_Id).filter((value, index, self) => self.indexOf(value) === index);
      distinctEnvelopes = ( [...new Map(openEnvelopeList.map(item =>[item['Unique_Envelope_Id'], item])).values()]); 
      if(distinctEnvelopes && distinctEnvelopes.length >0){
        let index = 0;
        distinctEnvelopes.forEach(env =>{
          let finalRequestList = [];
          let requestList = [];
          //filetered request list
          requestList = openEnvelopeList.filter(i => i.Unique_Envelope_Id == env.Unique_Envelope_Id &&
            (i.RequestStatus != 'Complete' && i.RequestStatus != 'Pending Archive' && i.RequestStatus != 'Archive Complete' && i.RequestStatus != 'Cancelled' ));          
          
          let distinctRequest = [];
          if(requestList && requestList.length >0){
            //distinctRequest = requestList.map(r => r.Unique_Request_Id).filter((value, index, self) => self.indexOf(value) === index);
            distinctRequest =  [...new Map(requestList.map(item =>[item['Unique_Request_Id'], item])).values()];
            if(distinctRequest && distinctRequest.length >0){
              let documentList = [];
              distinctRequest.forEach(req =>{
                  documentList = requestList.filter(d => d.Unique_Request_Id == req.Unique_Request_Id && 
                                 (d.DocumentStatus != 'Complete' && d.DocumentStatus != 'Pending Archive' && d.DocumentStatus != 'Archive Complete' &&
                                 d.DocumentStatus != 'Submitted' && d.DocumentStatus != 'Approved' && d.DocumentStatus != 'Rejected' && d.DocumentStatus != 'Cancelled' ));
                  
                  let finalDocumentList = [];
                  if(documentList && documentList.length >0){
                    documentList.forEach(doc =>{
                      finalDocumentList.push({
                        "RequestedDocumentId" : doc.RequestedDocumentId,
                        "RequestId" : doc.RequestId,
                        "DocumentGroupTypeName" : doc.DocumentGroupTypeName,
                        "DocumentTypeName" : doc.DocumentTypeName,
                        "DocumentStatus" : doc.DocumentStatus,
                        "Filename": doc.Filename,
                        "FilepathId" : doc.FilepathId,
                        "Doc_SubmittedOn" :doc.Doc_SubmittedOn,
                        "Doc_CancelledOn" : doc.Doc_CancelledOn,
                        "Doc_Approved_rejectedOn" : doc.Doc_Approved_rejectedOn,
                      });
                    });                    
                  }
                 
                  finalRequestList.push({
                    "RequestNumber" : req.RequestNumber,
                    "Unique_Request_Id":req.Unique_Request_Id,
                    "EnvelopeId" : req.EnvelopeId,
                    "ApplicantName" : req.ApplicantName,
                    "CommentsByRequestor" : req.CommentsByRequestor,
                    "RequestedBy" :req.RequestedBy,
                    "Requestor_Email":req.Requestor_Email,
                    "RequestStatus" : req.RequestStatus,
                    "CreationDate" : req.CreationDate,
                    "ModifiedDate" : req.ModifiedDate,
                    "SubmittedOn" :req.SubmittedOn,
                    "CancelledOn" : req.CancelledOn,
                    "Approved_rejectedOn" : req.Approved_rejectedOn,
                    "DocumentDetails" : finalDocumentList
                  });
              });
            }
           }
           finalRequestList = finalRequestList.filter(rec => rec.DocumentDetails && rec.DocumentDetails.length > 0);
          
           this.fullRequestDetails.push({
              "EnvelopeNumber" : env.EnvelopeNumber,
              "Unique_Envelope_Id" : env.Unique_Envelope_Id,
              "EnvelopeStatus" : env.EnvelopeStatus,
              "EnvelopeDescription" : env.EnvelopeDescription,
              "CompanyName" : env.CompanyName,
              "RequestDetails" : finalRequestList
           }); 
           
           if(isSubmitted && this.currentSelected >= 0){            
              let idx = this.activeIndexes.findIndex(elem => elem === this.currentSelected);            
              if (idx === -1){
                this.activeIndexes.push(this.currentSelected);
              }           
           }
           index +=1;
        });
      }   
      this.fullRequestDetails = this.fullRequestDetails.filter(rec => rec.RequestDetails && rec.RequestDetails.length > 0);         
       if(this.fullRequestDetails.length==1){
          this.activeIndexes.push(0);
        } 
    }
  
  }

  showUploadDocumentDialog(requestData,documentData,uniqueEnevlopeId,envNumber): void {
    let UploadDocumentDialog: BsModalRef;
    UploadDocumentDialog = this._modalService.show(
      UploadDocumentComponent,
      {
        class: 'modal-lg modal-dialog-centered',
        ignoreBackdropClick: true,
        initialState: {
          requestDetails : requestData,
          documentDetails : documentData,
          Unique_Envelope_Id : uniqueEnevlopeId,
          EnvelopeNumber : envNumber
        }
      }
    ); 
    UploadDocumentDialog.content.modalEmiiter.subscribe(() =>{  
        this.requestDetails(true);
    });  
    UploadDocumentDialog.content.modalEmiiterCancel.subscribe(() =>{  
      this.requestDetails(false);
    }); 
  }

}
