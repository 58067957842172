import { Component, OnInit,ElementRef,ViewChild,Output,EventEmitter, SecurityContext } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { PDFDocument,degrees,PageSizes  } from 'pdf-lib';
import {  DatePipe } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { AwsconnectService,ISubmitCustomerDocument } from '../../../shared/awsconnect.service';
//let swal = require('sweetalert2');
import swal from 'sweetalert2/dist/sweetalert2.js';
import { Router,ActivatedRoute } from '@angular/router';
import { FileUploadService } from '../../../shared/file-upload.service';

@Component({
  selector: 'app-view-pdf-confirm',
  templateUrl: './view-pdf-confirm.component.html',
  styleUrls: ['./view-pdf-confirm.component.scss']
})
export class ViewPdfConfirmComponent implements OnInit {
  title:string;
  pdfSrc :any;
  files :any;
  requestData : any;
  @ViewChild('pdfviewobj', {static: false})pdfviewobj: ElementRef;
  @Output() onCancel = new EventEmitter<any>();
  @Output() onSave = new EventEmitter<any>();
  requestNumber :string;
  documentName :string;
  submitLoader:boolean=false;
  envelopeNumber:string;
  requestor :string;
  requestor_email:string;
  submitfileName : string;
  submitfile:any;

  constructor(public bsModalRef: BsModalRef,private _datepipe : DatePipe,
    private sanitizer : DomSanitizer,
    private _awsconnectService: AwsconnectService,private _FileUploadService : FileUploadService,
    private route: ActivatedRoute,private _router: Router) { }


  ngOnInit(): void {
      this.title = "Ready to submit?The documents will be sent for review, and you will not be able to make any changes till the process is complete.";
      this.mergeFilesIntoPdf();
  }

  //embed image documents
  async mergeFilesIntoPdf(){
    try{
        const pdfDoc = await PDFDocument.create();
        if(this.files.length>0){
          for(let i = 0;i< this.files.length ;i++){  
            if(this.files[i].url){
              if(this.files[i].type.toLowerCase() != "application/pdf" && this.files[i].type.toLowerCase() != "pdf") { 
                  let imgBytes =  await this.embed_doc(this.files[i].url);         
                  let image ;
                  if(this.files[i].type.toLowerCase() == 'jpg' || this.files[i].type.toLowerCase() =="image/jpg" ||
                      this.files[i].type.toLowerCase() =="image/jpeg" || this.files[i].type.toLowerCase() =="jpeg"){
                    image = await pdfDoc.embedJpg(imgBytes);             
                    const jpgDims = image.scale(0.18);
                    const page = pdfDoc.addPage(PageSizes.A4);
                    page.drawImage(image, {
                      x: page.getWidth() / 2 - jpgDims.width / 2,
                      y: page.getHeight() / 2 - jpgDims.height / 2 +  100,
                      width: jpgDims.width,
                      height: jpgDims.height,
                    }) ;  
                }else if(this.files[i].type.toLowerCase() == 'png' || this.files[i].type.toLowerCase() =="image/png"){
                  image = await pdfDoc.embedPng(imgBytes);  
                    const jpgDims = image.scale(0.3);
                    const page = pdfDoc.addPage(PageSizes.A4);
                  page.drawImage(image, {
                    x: page.getWidth() / 2 - jpgDims.width / 2 ,
                    y: page.getHeight() / 2 - jpgDims.height / 2 + 100,
                    width: jpgDims.width,
                    height: jpgDims.height,
                  }) ;               
                }            
              }else{      
                let pdfBytes =  await this.embed_doc(this.files[i].url);

                const pdfDocUploaded = await PDFDocument.load(pdfBytes);
                if(pdfDocUploaded.getPages().length > 1){
                  for(let i= pdfDocUploaded.getPages().length-1 ;i>=0 ;i--){
                    const [pdfPages] = await pdfDoc.copyPages(pdfDocUploaded,[i]);                    
                    pdfDoc.insertPage(0, pdfPages);
                    
                    /*const pdfPages = await pdfDoc.embedPage(pdfDocUploaded.getPages()[i]);
                    const pdfPageDims = pdfPages.scale(0.5);
                    const page = pdfDoc.addPage(PageSizes.A4);
                    page.drawPage(pdfPages,{
                      x: page.getWidth()/2  - pdfPageDims.width / 2,
                      y: page.getHeight()/2 - pdfPageDims.height / 2 -50,
                      width: pdfPageDims.width,
                      height: pdfPageDims.height,
                    });*/
                  }
                }else{
                  const [pdfPage] = await pdfDoc.embedPdf(pdfBytes);
                  const pdfPageDims = pdfPage.scale(0.5);
                  const page = pdfDoc.addPage(PageSizes.A4);
                  
                  page.drawPage(pdfPage,{
                    x: page.getWidth()/2  - pdfPageDims.width / 2,
                    y: page.getHeight()/2 - pdfPageDims.height / 2 -50,
                    width: pdfPageDims.width,
                    height: pdfPageDims.height,
                  });
                }                
              }
            }
          }
          
          const pdfBytes = await pdfDoc.save();
          this.download(pdfBytes);
      }
    }catch(e){
      swal.fire('Error',"Error generating pdf view,please try again" + e,'error');
      throw e;
    }
  }
  async embed_doc(imgUrl){
   return await fetch(imgUrl).then((res) => res.arrayBuffer());  
  }

  download(pdfBytes){
    let file = new Blob([pdfBytes], { type: 'application/pdf' });            
    var fileURL = URL.createObjectURL(file);
    const a = document.createElement('a');
    a.href = fileURL;
    a.download = this.requestNumber+ "_" + this.documentName +"_" + this._datepipe.transform(Date(), 'yyyyMMddhhmmss') + ".pdf";
    document.body.appendChild(a);  
    
    //var url = window.URL.createObjectURL(file,{ type: 'application/pdf' });
    this.pdfSrc = this.sanitizer.bypassSecurityTrustResourceUrl(fileURL);
    this.submitfileName = a.download;
    this.submitfile = file;
    //a.click();
    //document.body.removeChild(a);
   // URL.revokeObjectURL(fileURL);
    const reader = new FileReader();
    let fileContent;
   // let filetoUpload = [];
    reader.readAsDataURL(file);
    reader.onload = () => {
      fileContent = reader.result;
     /* filetoUpload.push({
        "fileName" : a.download,
        "fileContent" :fileContent
      });
      //console.log(filetoUpload)
      this.requestData.filedetails = filetoUpload;*/
      //this.finalSubmitDocument(filetoUpload);
    };
  }

  cancelModal(){    
    this.bsModalRef.hide();
    this.onCancel.emit();
  }

  async finalSubmit(){
    this.submitLoader = true;
    if(this.submitfile.size > (25*1000*1000)){
      swal.fire('Error',"File size exceeds 25 MB ,cannot be submitted",'error');
      this.submitLoader = false;
      return;          
    }
    let docName = this.requestData.documentName +'_'+ this.requestData.requested_document_details_Id;
    let stageFileName = this.requestData.customerIdentifier +'/'+ this.requestData.unique_env_id +'/'+this.requestData.unique_req_id +
    '/' + docName + '/final/' + this.requestData.unique_req_id +'_'+ this.requestData.requested_document_details_Id + '_' + this.submitfileName
  
   await this._FileUploadService.getSignedUrl(this.submitfile,stageFileName).subscribe( url =>{  
      this._FileUploadService.uploadfileAWSS3(url,this.submitfile.type,this.submitfile).subscribe(upl =>{ 
        if(upl && upl['type']==4){
         let filetoUpload = [];
         filetoUpload.push({"fileName" : this.submitfileName });
         this.requestData.filedetails = filetoUpload;
          this._awsconnectService.submitDocument(this.requestData).subscribe(
            res=>{
              this.submitLoader = false;
              this.bsModalRef.hide();  
              swal.fire("Success",'Document Submitted Successfully');
              this.sendEmail();
              this.onSave.emit();
            },error=>{
              this.submitLoader = false;
              swal.fire('Error',"Error submitting the document "+ error,'error');
            });
          }
      },error=>{
        swal.fire('Error',"Error uploading the document "+ error,'error');
      });
    },error=>{
      swal.fire('Error',"Error getting the signed url "+ error,'error');
    }); 
  }

  /*finalSubmitDocument(){
    this.submitLoader = true;
    this._awsconnectService.uploadSubmittedDocuments(this.requestData).subscribe(
      res =>{
        this.submitLoader = false;
        this.bsModalRef.hide();     
        this.sendEmail();  
        swal.fire("Success",'Document Submitted Successfully');
        this.onSave.emit();
       // this._router.navigate(['/app/envelope/viewrequest/' + this.requestData.unique_env_id]);
      },error=>{
        this.submitLoader = false;
        //this.savingData = false;
        swal.fire('Error',"Error submitting the document "+ error,'error');
        //console.log(error);
      }
    );
  }*/

  sendEmail(){
    let params = "requestNumber=" + this.requestNumber + "&documentName=" + this.documentName + "&customerName=" +  localStorage.getItem('userName') + "&envelopeNumber=" + this.envelopeNumber + "&requestorEmail=" + this.requestor_email + "&requestor=" + this.requestor;
    this._awsconnectService.sendEmail(params).subscribe(()=>{});
  }


}
