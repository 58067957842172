<footer>
       <div class="container">
         <!-- <div class="d-flex pb-4 px-4 px-sm-0">
              <div class="w-100">
                  <p class="m-0 max-w-700 text-muted font-size-xs">The Financial Fitness tool is for informational purposes only and should not be considered investment, tax or legal advice. In instances where a managed account is offered/available additional terms and conditions of service will apply.</p>
              </div>
          </div> -->
          <div class="d-flex align-center flex-wrap mb-2">
             <h6 class="text-theme-color mb-0 mr-3 font-weight-normal font-size-xs line-height-normal">©2023 Alerus Financial</h6>
              <a href="https://alerus.com/about/privacy-legal/" class="mr-3 font-size-xs text-active-color line-height-normal">Privacy</a>
              <a href="https://alerus.com/accessibility/" class="mr-3 font-size-xs text-active-color line-height-normal">Accessibility</a>
              <a href="javascript:void(0);" class="mr-3 font-size-xs line-height-normal">833.325.3787</a>
          </div>
         </div>
  </footer>
