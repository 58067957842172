import { Injectable ,Inject} from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree,Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { environment } from '../../environments/environment';
import { env } from 'process';
import { ConfigServiceService } from '../shared/config-service.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardGuard implements CanActivate {

  constructor(private router: Router, private authService: AuthService, private configService: ConfigServiceService){  }

  canActivate(next: ActivatedRouteSnapshot,state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        // var c = document.cookie.split(';');
    // console.log(c.length)
    // if(c.length > 1){
    // var d = c[2].split('=')[1];
    //   console.log(d)
    //   if(d == null){
    //   let isAuth =  this.authService.authenticate();
    //   if(!isAuth) {
    //       isAuth = this.authService.federatedSign();
    //           //window.location.href = environment.loginUrl; //+ "?response_type=token&client_id="+ environment.cognito.userPoolWebClientId + "&redirect_uri="+ environment.redirectUrl;        
    //     }
    //     return isAuth;    
    //   }else{
    //     return true;
    //   }
    // }else{  
       
    return this.confirmAuth(); 
  }

  async confirmAuth(): Promise<any>{
    let isAuth;
    return await this.authService.authenticate().then(res =>{
      //alert(res)
        if(res == false){
             if(this.configService.config.redirectUrl.includes('localhost:8080')){
               this.authService.federatedSign().subscribe(fed =>{
               return true;            
             });
           }else{
            window.location.href = this.configService.config.loginUrl;
          }
        }
        else{
            return true;
        }
    },error=>{
      if(this.configService.config.redirectUrl.includes('localhost:8080')){
            this.authService.federatedSign().subscribe(fed =>{
            return true;            
          });
        }else{
        window.location.href = this.configService.config.loginUrl;
      }
    });
  }
}