<header>
  <div class="container">
    <nav class="navbar navbar-expand-lg navbar-light p-0 bg-white d-none d-lg-flex">
      <a class="navbar-brand" href="#">
        <img class="max-w-144" src="/assets/images/Logo.svg" alt="alerus logo">
      </a>    
      <div class="collapse navbar-collapse h-100">
        <!-- <ul class="d-flex align-items-center list-unstyled m-0 w-100 h-100 mr-4 pr-4">
          <li class="ml-4"><a href="">Accounts</a></li>
          <li class="ml-4 active"><a href="">Financial Wellness</a></li>
          <li class="ml-4"><a href="">Budget</a></li>
          <li class="ml-auto"><a href="">My Documents</a></li>
        </ul> -->
        <div class="d-flex align-items-center my-2 my-lg-0 ml-auto flex-shrink-0">
          <div ngbDropdown class="custom-dropdown">
            <div class="d-flex align-items-center" ngbDropdownToggle>
              <img class="mr-2" src="/assets/images/icon-user-circle.svg" alt="user icon">
              <span>{{userName}}</span>
            </div>
            <!-- <div ngbDropdownMenu aria-labelledby="userDropdown">
              <button ngbDropdownItem>Option - 1</button>
              <button ngbDropdownItem>Option - 2</button>
              <button ngbDropdownItem>Option - 3</button> 
            </div>-->
          </div>
          <button class="btn btn-outline-secondary my-2 my-sm-0 ml-4 py-2 rounded-0" (click)="signOut()">Log out</button>
        </div>
      </div>
    </nav>
    <nav class="navbar navbar-expand-lg navbar-light py-0 bg-white d-lg-none justify-content-start px-4 px-sm-0">
      <a class="navbar-brand h-100 d-flex align-items-center border-right pr-4" href="#">
        <img src="/assets/images/icon-logo.svg" alt="alerus logo">
      </a>
      <div ngbDropdown class="custom-dropdown">
        <div class="d-flex align-items-center" ngbDropdownToggle>
          <img class="mr-2" src="/assets/images/icon-user-circle.svg" alt="user icon">
          <span>{{userName}}</span>
        </div>
        <!-- <div ngbDropdownMenu aria-labelledby="userDropdown">
          <button ngbDropdownItem>Option - 1</button>
          <button ngbDropdownItem>Option - 2</button>
          <button ngbDropdownItem>Option - 3</button> 
        </div>-->
      </div>
      <button class="navbar-toggler ml-auto h-100 d-flex align-items-center pl-4" type="button" (click)="clickEvent()" data-toggle="collapse" data-target="#navbarToggler" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse border-top p-4" id="navbarToggler" [ngClass]="mobMenu ? 'open' : ''">
        <div class="d-flex align-items-center">
          <button class="btn btn-outline-secondary ml-auto py-2 rounded-0" (click)="signOut()">Log out</button>
        </div>
      </div>
    </nav>
  </div>
  <div class="header-bg"></div>
</header>