<app-header></app-header>
<section class="content-area">
  <div class="container">
    <div class="mb-4">
      <div class="card-box bg-white card-box-active px-4 pb-5">
        <div class="pt-5" *ngIf="fullRequestDetails.length==0 && !loader">
          <div class="d-flex flex-column align-items-center justify-content-center py-5">
            <p class="m-0">No documents required at this time. Please contact your business advisor if you have questions.</p>
          </div>
        </div>
        <div class="pt-5" *ngIf="fullRequestDetails.length==0 && loader">
          <div class="d-flex flex-column align-items-center justify-content-center py-5">
            <i class="fa-solid fa-spinner fa-spin-pulse fa-2xl"></i>
          </div>
        </div>
        <p-accordion *ngIf="fullRequestDetails && fullRequestDetails.length>0" [multiple]="true" [activeIndex]="activeIndexes"> 
          <p-accordionTab *ngFor="let data of fullRequestDetails; let i= index;"> 
            <ng-template pTemplate="header">
              <div class="d-flex flex-column py-4">
                <h5 class="font-size-md text-uppercase mb-1">{{data.EnvelopeDescription}} -  {{data.CompanyName}}</h5>
                <div class="d-flex align-items-center">
                  <span class="mr-3 font-weight-normal">{{data.EnvelopeNumber}}</span>
                  <span class="status warning">{{data.EnvelopeStatus}}</span>
                </div>
              </div>
            </ng-template>
            <ng-template pTemplate="body">
              <div class="px-4" *ngIf="data.RequestDetails && data.RequestDetails.length>0">
                <div class="d-flex justify-content-between mb-4" *ngFor="let reqData of data.RequestDetails">
                  <div class="d-flex flex-column w-100">
                    <div class="d-flex align-items-center mb-2">
                      <p class="m-0 mr-2 font-weight-semibold">{{reqData.CommentsByRequestor}}</p>
                      <!-- <span class="status" [ngClass]="{'new':reqData.RequestStatus=='New',
                                                        'pending': reqData.RequestStatus == 'Submitted',
                                                        'success': reqData.RequestStatus == 'Approved',
                                                        'danger': reqData.RequestStatus == 'Rejected',
                                                        'warning': reqData.RequestStatus == 'Cancelled'}">
                          {{reqData.RequestStatus}}
                      </span> -->
                      <p class="m-0 mr-2 font-weight-normal" [hidden]="!reqData.ApplicantName"> 
                           Documents for 
                          <span class="m-0 mr-2 font-weight-semibold"> {{reqData.ApplicantName}} </span> 
                      </p>
                    </div>
                    <div *ngIf="reqData.DocumentDetails && reqData.DocumentDetails.length>0">
                      <div class="d-flex justify-content-between" *ngFor="let docData of reqData.DocumentDetails">
                        <div class="d-flex align-items-start mb-1">
                          <!-- <span class="mr-4 font-weight-semibold text-active-color">{{reqData.RequestNumber}}</span> -->
                          <div class="">
                            <p class="mb-1">{{docData.DocumentTypeName}} on {{reqData.CreationDate | date: 'MM.dd.yyyy'}}</p>
                            <p class="mb-1" [hidden]="(docData.DocumentStatus != 'Submitted' && (docData.DocumentStatus != 'Approved' && docData.DocumentStatus != 'Rejected'))">
                              Submitted on {{docData.Doc_SubmittedOn != true ? (docData.Doc_SubmittedOn | date: 'MM.dd.yyyy') : (reqData.SubmittedOn  | date: 'MM.dd.yyyy')}}
                            </p>
                            <p class="mb-1" [hidden]="(docData.DocumentStatus != 'Approved' && docData.DocumentStatus != 'Rejected')">{{docData.DocumentStatus == 'Approved' ? 'Approved' : 'Rejected'}} on {{docData.Doc_Approved_rejectedOn != true ? (docData.Doc_Approved_rejectedOn | date: 'MM.dd.yyyy') : (reqData.Approved_rejectedOn | date: 'MM.dd.yyyy')}}</p>
                            <p class="mb-1" [hidden]="docData.DocumentStatus != 'Cancelled'">Cancelled on {{docData.Doc_CancelledOn != true ? (docData.Doc_CancelledOn | date: 'MM.dd.yyyy') : (reqData.CancelledOn  | date: 'MM.dd.yyyy')}}</p>
                            <p class="mb-1" [hidden]="docData.DocumentStatus != 'In Progress'">Document Status is {{docData.DocumentStatus}}</p>
                          </div> 
                        </div>
                        <div class="mb-2" [hidden]="docData.DocumentStatus == 'Cancelled'">
                          <button class="btn btn-danger" (click)="showUploadDocumentDialog(reqData,docData,data.Unique_Envelope_Id,data.EnvelopeNumber);currentSelected = i" [hidden]="(docData.DocumentStatus!='New' && docData.DocumentStatus!='In Progress')">Upload</button>
                          <button class="btn btn-outline-danger" [hidden]="(docData.DocumentStatus=='New' || docData.DocumentStatus=='In Progress')" (click)="showUploadDocumentDialog(reqData,docData,data.Unique_Envelope_Id,data.EnvelopeNumber)">View</button>
                        </div>
                      </div>
                    </div>
                  </div>                  
                </div>
              </div>
              <div class="px-0" *ngIf="!data.RequestDetails || data.RequestDetails.length==0">
                <p class="mb-4">No Open Requests</p>
              </div>
            </ng-template>
          </p-accordionTab>
        </p-accordion>
      </div>
    </div>
  </div>
</section>
<app-footer></app-footer>