import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders,  HttpRequest } from '@angular/common/http';
import { observable, Observable } from 'rxjs';
import { ConfigServiceService } from '../shared/config-service.service';
import * as AWS from 'aws-sdk';


@Injectable({
  providedIn: 'root'
})
export class FileUploadService {

  constructor(private http: HttpClient, private configService: ConfigServiceService) { 
    AWS.config.update({
      secretAccessKey: this.configService.config.s3Config.secretAccessKey,
      accessKeyId: this.configService.config.s3Config.accessKeyId,
      region: this.configService.config.s3Config.region, 
      signatureVersion: this.configService.config.s3Config.signatureVersion,
    });
  }

  uploadfileAWSS3(fileuploadurl, contenttype, file): Observable<any>{  
    const headers = new HttpHeaders({ 'Content-Type': contenttype }); 
    const  req =   new HttpRequest(
        'PUT',
        fileuploadurl,
        file,
        {
          headers: headers, 
        });
      
    const uploadresponse = new Observable(observer => {   
      this.http.request<any>(req).subscribe(response =>{
        observer.next(response);
      },error=>{
        observer.error(error);
      });
    });
    return uploadresponse;
  }  

  getSignedUrl(file,filename){
    const contentType = file.type;
    const bucket = new AWS.S3();
      const params = {
        Bucket: this.configService.config.s3Config.stagingBucketName,
        Key: filename,
        Expires: this.configService.config.s3Config.expires, // Time untill presigned URL is valid
        ACL: 'private',
        ContentType: contentType
      };
      let preSignedUrl = new Observable(observer =>{
        return bucket.getSignedUrl('putObject', params,function async(err, url)
        {
          if (url) { 
            observer.next(url);
          }if(err){
            observer.error(err);
          }
        });
      });
      return preSignedUrl;
  }


  /*uploadFile(file) {
    
    const contentType = file.type;
    const bucket = new AWS.S3(
          {
              accessKeyId: 'AKIATOXIZHY6PCDAVQWX',
              secretAccessKey: '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',
              region: 'us-east-1'
          }
      );
    
          const params = {
            Bucket: 'secure-doc-exchange-staging',
            Key: file.name,
            Body: file.file,
            ACL: 'private',
            ContentType: contentType
          };

        bucket.upload(params, function (err, data) {
          if (err) {
              console.log('There was an error uploading your file: ', err);
              return false;
          }
          console.log('Successfully uploaded file.', data);
          return true;
        });     
      
}*/

}
