import { Injectable } from '@angular/core';
import { Inject,VERSION } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { environment } from '../../environments/environment';
import { AuthenticationDetails, CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js';
import { CognitoIdentityServiceProvider } from 'aws-sdk';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { observable, Observable, throwError } from 'rxjs';
import { ConfigServiceService } from '../shared/config-service.service';
import { AuthService } from '../auth/auth.service';
let axios = require('axios');
let swal = require('sweetalert2');

export interface ISubmitCustomerDocument{
  requestId :number;
  unique_req_id:string;
  unique_env_id : string;
  customerIdentifier:string;
  requested_document_details_Id : number;
  documentName:string;
  filedetails : any;
}

// var poolData = {
//   UserPoolId: environment.cognito.userPoolId, // Your user pool id here
//   ClientId: environment.cognito.userPoolWebClientId // Your client id here
// };

// var userPool = new CognitoUserPool(poolData);

@Injectable({
  providedIn: 'root'
})
export class AwsconnectService {

  baseURL: string ;

  constructor(private httpClient: HttpClient, private configService: ConfigServiceService,private authService: AuthService) {
    //  @Inject(DOCUMENT) private document: Document){
    //   if (window.location.href.indexOf('/?auth=') > -1) {
    //     if (!sessionStorage.getItem('auth')) {
    //       let reg = /(auth=)(.*)(&)/;
    //       let result = reg.exec(window.location.href);
    //       if (result?.length > 2) {
    //         console.log(result)
    //         sessionStorage.setItem('auth', result[2]);
    //       }
    //   }
    // }
    this.baseURL = configService.config.apiGateway;
   }


  getEnvelopeList(params): Observable<any> {
    let token  = localStorage.getItem('token');
    const responseList = new Observable(observer => {
      axios.get(this.baseURL + 'securedocxchange_getenvelopelist?'+ params,{headers : {"Authorization": token}})
      .then(
        response => {  
          observer.next(response.data);        
        },error => {
          observer.error(error);
          swal.fire('Error',"Error while getting envelope list "+ error,'error');
        });
    });
    return responseList;
   }    

   getRequestList(params):Observable<any>{
    let token  = localStorage.getItem('token');
    const responseList = new Observable(observer =>{
        axios.get(this.baseURL + 'securedocxchange_getrequestlistbyenvelopeid?' + params, {headers :{"Authorization" : token}})
        .then(response =>{
            observer.next(response.data);
        },error=>{
          observer.error(error);
          swal.fire('Error',"Error while getting request list "+ error,'error');
        })
    });
    return responseList;
   }

   getRequestedDocuments(params):Observable<any>{    
      let token  = localStorage.getItem('token');
      const responseList = new Observable(observer =>{
        axios.get(this.baseURL + 'securedocxchange_getrequesteddocumentsbyid?' + params, {headers :{"Authorization" : token}})
        .then(response =>{
            observer.next(response.data);
        },error=>{
          observer.error(error);
          swal.fire('Error',"Error while fetching requested documents "+ error,'error');
        });
    });
    return responseList;
   }

   getRequestDetails(params):Observable<any>{ 
    let token  = localStorage.getItem('token');
    const responseList = new Observable(observer =>{
      axios.get(this.baseURL + 'securedocxchange_getrequestdetailsbyid?' + params, {headers :{"Authorization" : token}})
      .then(response =>{
          observer.next(response.data);
      },error=>{
        observer.error(error);
        swal.fire('Error',"Error while fetching request details "+ error,'error');
      });
    });
    return responseList;
   }


   uploadSubmittedDocuments(input : ISubmitCustomerDocument):Observable<any>{
      const body = { 
        "requestId": input.requestId,
        "unique_req_id" : input.unique_req_id,
        "unique_env_id":input.unique_env_id,
        "requested_document_details_Id": input.requested_document_details_Id,
        "customerIdentifier": input.customerIdentifier,
        "fileDetails":input.filedetails,
        "documentName" :input.documentName
    };
    if(!localStorage.getItem('token')){
      swal.fire('Error','For our customers security you have been logged out','error');   
      this.redirectonSessionOut();
      return;
    }
    
      let token  = localStorage.getItem('token');
      const headers_j = new HttpHeaders();
      headers_j.append('Authorization', token);
      headers_j.append('Content-Type', 'multipart/form-data');
      headers_j.append('Accept', 'application/json'); 

      const responseList = new Observable(observer => {   
        this.httpClient.post<any>(this.baseURL + 'securedocxchange_submit_uploaddocuments',JSON.stringify(body),{headers : {"Authorization": token}})
        .subscribe(
          response => { 
            //console.log(response) 
            observer.next(response);
          },
          error => {
            observer.error(error);
            if(error.message == 'Network Error'){
              swal.fire('Error','For our customers security you have been logged out','error');   
              this.redirectonSessionOut();
              return;
            }else{
             swal.fire('Error',"Error while submitting document "+ error,'error');
            }
          });
      });
      return responseList;
   }

   submitDocument(input : ISubmitCustomerDocument):Observable<any>{
    const body = { 
      "requestId": input.requestId,
      "unique_req_id" : input.unique_req_id,
      "unique_env_id":input.unique_env_id,
      "requested_document_details_Id": input.requested_document_details_Id,
      "customerIdentifier": input.customerIdentifier,
      "fileDetails":input.filedetails,
      "documentName" :input.documentName
    };

    if(!localStorage.getItem('token')){
      swal.fire('Error','For our customers security you have been logged out','error');   
      this.redirectonSessionOut();
      return;
    }
        
    let token  = localStorage.getItem('token');
    const responseList = new Observable(observer => {   
      this.httpClient.post<any>(this.baseURL + 'securedocxchange_submitdocuments',JSON.stringify(body),{headers : {"Authorization": token}})
        .subscribe(
          response => { 
            observer.next(response);
          },
          error => {
            observer.error(error);
            if(error.message == 'Network Error'){
              swal.fire('Error','For our customers security you have been logged out','error');   
              this.redirectonSessionOut();
              return;
            }else{
              swal.fire('Error',"Error while submitting document "+ error,'error');
            }
          });
      });
      return responseList;
   }  

   stageFilesonUpload(input : ISubmitCustomerDocument):Observable<any>{
      const body = { 
          "requestId": input.requestId,
          "unique_req_id" : input.unique_req_id,
          "unique_env_id":input.unique_env_id,
          "requested_document_details_Id": input.requested_document_details_Id,
          "customerIdentifier": input.customerIdentifier,
          "fileDetails":input.filedetails,
          "documentName" :input.documentName
      };

      if(!localStorage.getItem('token')){
        swal.fire('Error','For our customers security you have been logged out','error');   
        this.redirectonSessionOut();
        return;
      }

      let token  = localStorage.getItem('token');
      const headers_j = new HttpHeaders();
      headers_j.append('Authorization', token);
      headers_j.append('Content-Type', 'multipart/form-data');
      headers_j.append('Accept', 'application/json'); 

      const responseList = new Observable(observer => {   
        this.httpClient.post<any>(this.baseURL + 'securedocxchange_save_uploaddoc_stage',JSON.stringify(body),{headers : {"Authorization": token}})
        .subscribe(
          response => { 
            //console.log(response) 
            observer.next(response);
          },
          error => {
            observer.error(error);  
            if(error.message == 'Network Error'){
              swal.fire('Error','For our customers security you have been logged out','error');   
              this.redirectonSessionOut();
              return;
            }else{        
              swal.fire('Error', error.error + " Error processing this document.",'error');            
            }
          });
      });
      return responseList;
   }

   stageDocuments(input){
    const body = { 
      "requestId": input.requestId,
      "requested_document_details_Id": input.requested_document_details_Id,
      "fileName" : input.fileName
    };

    if(!localStorage.getItem('token')){
      swal.fire('Error','For our customers security you have been logged out','error');   
      this.redirectonSessionOut();
      return;
    }

    let token = localStorage.getItem('token');
    const responseList = new Observable(observer =>{
      this.httpClient.post(this.baseURL + 'securedocxchange_stagedocument',JSON.stringify(body), {headers :{"Authorization" : token}})
      .subscribe(response =>{
          observer.next(response);
      },error=>{
        observer.error(error);
        if(error.message == 'Network Error'){
          swal.fire('Error','For our customers security you have been logged out','error');   
          this.redirectonSessionOut();
          return;
        }else{        
          swal.fire('Error',"Error while fetching presigned url "+ error,'error');
        }
      });
    });
    return responseList;
  }

   getStagedDocument(params):Observable<any>{
    if(!localStorage.getItem('token')){
      swal.fire('Error','For our customers security you have been logged out','error');   
      this.redirectonSessionOut();
      return;
    }
    let token  = localStorage.getItem('token');
    const responseList = new Observable(observer =>{
      axios.get(this.baseURL + 'securedocxchange_getstageddocuments?' + params, {headers :{"Authorization" : token}})
      .then(response =>{
          observer.next(response.data);
      },error=>{
        observer.error(error);
        if(error.message == 'Network Error'){
          swal.fire('Error','For our customers security you have been logged out','error');   
          this.redirectonSessionOut();
          return;
        }else{        
          swal.fire('Error',"Error while fetching staged documents "+ error,'error');
        }
      });
    });
    return responseList;
   }

   deleteStagedDocuments(docKeys):Observable<any>{
    if(!localStorage.getItem('token')){
      swal.fire('Error','For our customers security you have been logged out','error');   
      this.redirectonSessionOut();
      return;
    }

    let token  = localStorage.getItem('token');
    const body = { 
        "docKey": docKeys
    };
      const responseList = new Observable(observer =>{
       axios.delete(this.baseURL + 'securedocxchange_deletefile_staging',{ data: body , headers :{"Authorization" : token}})
        .then(response =>{
            observer.next(response);
        },error=>{
          observer.error(error);
          if(error.message == 'Network Error'){
            swal.fire('Error','For our customers security you have been logged out','error');   
            this.redirectonSessionOut();
            return;
          }else{        
            swal.fire('Error',"Error while deleting staged documents "+ error,'error');
          }
        });
      });
      return responseList;
   }

   getSubmittedDocument(params):Observable<any>{
    if(!localStorage.getItem('token')){
      swal.fire('Error','For our customers security you have been logged out','error');   
      this.redirectonSessionOut();
      return;
    }
    let token  = localStorage.getItem('token');
    const responseList = new Observable(observer =>{
      axios.get(this.baseURL + 'securedocxchange_getsubmitteddocuments?' + params, {headers :{"Authorization" : token}})
      .then(response =>{
          observer.next(response.data);
      },error=>{
        observer.error(error);
        if(error.message == 'Network Error'){
          swal.fire('Error','For our customers security you have been logged out','error');   
          this.redirectonSessionOut();
          return;
        }else{
          swal.fire('Error',"Error while fetching submitted documents "+ error,'error');
        }
      });
    });
    return responseList;
   }

   sendEmail(params){
    let token  = localStorage.getItem('token');
    const responseList = new Observable(observer =>{
      axios.get(this.baseURL + 'securedocxchange_sendemailaftersubmission?' + params, {headers :{"Authorization" : token}})
      .then(response =>{
          observer.next(response.data);
      },error=>{
        observer.error(error);
       // swal.fire('Error',"Error while fetching submitted documents "+ error,'error');
      });
    });
    return responseList;
  }

  //external api
  getAdvisorDetails(){
    let params = "apiName=callToAction&apiEndPoint="+this.configService.config.alerusApi+"&authToken="+ localStorage.getItem('accessToken')+"&alerusAccountKey="+localStorage.getItem('alerusId');
    let token  = localStorage.getItem('token');
    const responseList = new Observable(observer =>{
      axios.get(this.baseURL + 'securedocxchange_getadvisordetails?'+ params,{headers :{"Authorization" : token}})
      .then(response =>{
          observer.next(response);
      },error=>{
        observer.error(error);
        swal.fire('Error',"Error while getting advisor details "+ error,'error');
      });
    });
    return responseList;
  }

  redirectonSessionOut(){
    if(this.configService.config.redirectUrl.includes('localhost:8080')){
      this.authService.federatedSign().subscribe(fed =>{
      return true;            
    });
    }else{
      window.location.href = this.configService.config.loginUrl;
    }
  }

  //fileScanner
  /*scanFile(file){
    var formData: any = new FormData();
    formData.append('avatar', file);
    const body = {
      "avatar":file
    }
    let scanner_api =  this.configService.config.fileScannerApi;
    const response = new Observable(observer =>{
      this.httpClient.post<any>(scanner_api+'avatar-upload',formData).subscribe(
        response=>{
          observer.next(response);
        },
        error => {
          observer.error(error);
          swal.fire('Error',"Error while scanning the document "+ error,'error');            
        });
    });
    return response;
  }*/

  getCustomerFullRequestDetails(params):Observable<any>{
    if(!localStorage.getItem('token')){
      swal.fire('Error','For our customers security you have been logged out','error');   
      this.redirectonSessionOut();
      return;
    }

    let token = localStorage.getItem('token');
    const responseList = new Observable(observer =>{
      axios.get(this.baseURL + 'securedocxchange_getfullrequestdetailsofcustomer?' + params, {headers :{"Authorization" : token}})
      .then(response =>{
          observer.next(response.data);
      },error=>{
        observer.error(error);
        if(error.message == 'Network Error'){
          swal.fire('Error','For our customers security you have been logged out','error');   
          this.redirectonSessionOut();
          return;
        }else{        
          swal.fire('Error',"Error while fetching requested data "+ error,'error');
        }
      });
    });
    return responseList;
  } 
  
}
