import { Component,OnInit,Inject,VERSION } from '@angular/core';
import { AuthService } from './auth/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { AwsconnectService } from './shared/awsconnect.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'SecureDocumentExchangeCustomerApp';
  loading = false;

  constructor(private _authService: AuthService,private _awsconnectService : AwsconnectService){
   
  }
  ngOnInit(){
  }

  

 }
